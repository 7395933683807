import { feedbackIntegration } from '@sentry/nuxt'

export const useSentry = (t?: any) => {
	const { segmentEvent } = useSegment()

	const promptFeedBackForm = async () => {
		if (import.meta.prerender || import.meta.server) { return }
		const feedback = feedbackIntegration({
			autoInject: false,
			showName: false,
			showEmail: false,
			enableScreenshot: false,
			colorScheme: 'dark',
			buttonLabel: t('feedback.report.title'),
			submitButtonLabel: t('feedback.reportModal.cta'),
			cancelButtonLabel: t('general.cancel'),
			formTitle: t('feedback.reportModal.title'),
			messageLabel: t('feedback.reportModal.input.label'),
			messagePlaceholder: t('feedback.reportModal.subtitle', { account: 'support', domain: 'singa.com' }),
			successMessageText: t('feedback.reportModal.success.body'),
			showBranding: false,
			onSubmitError: (error: Error) => { console.error(error) },
			onSubmitSuccess: (data: Record<string, any>) => {
				segmentEvent('Feedback Submitted', { feedback_text: data.message })
			}
		})
		if (!feedback) {
			return null
		}
		const form = await feedback.createForm()
		form.appendToDom()
		form.open()
	}

	return {
		promptFeedBackForm
	}
}
