<template lang="pug">
.navigation-wrap(:class="[ hasScrolledHalfView ? 'sticky' : '', exitSticky ? 'exit-sticky' : '']" )
	//- Desktop
	.navigation-desktop(:class="[{'bg-transparent': isSearchView }, indexTabs ? 'has-navigation-tabs' : '']")
		.container.section.nav-inner
			transition(name="fade-absolute-left")
				NuxtLink.flex-child(v-if="!isSearchView" :to="localePath('/')" @click="goHome()")
					SingaLogo.singa-logo.start(:imgSrc="story.logo?.filename")

			.flex-child
				SearchInput#search-input.search-wrap(v-if="!isVenuePage && !isVenueSubPage" @focus="isSearchView = true" @focusout="isSearchView = false" :placeholderText="story.cta_search_input" :class="{'expanded': isSearchView}")
				SearchVenueSearchInput#search-input.search-wrap(v-else-if="isVenueSubPage" @focus="isSearchView = true" @focusout="isSearchView = false" :placeholderText="story.venue_page_search_cta" :class="{'expanded': isSearchView}")

			transition(name="fade-absolute-right")
				.flex-child(v-if="!isSearchView")
					client-only
						.nav-right(v-if="auth.isLoggedIn && user")
							SingaButton.is-regular.is-primary.is-rounded.upsell-btn(v-if="!hasPremiumSub" :href="story.get_premium_link.cached_url" :target="story.get_premium_link.target" tag="a") {{ story.get_premium_cta }}
							SingaDropdown.nav-menu.nav-user-dropdown(aria-role="list" :mobile-modal="false" position="bottom-left" itemActiveClass="")
								template(#trigger="{ active }")
									SingaButton.is-transparent-dark.is-regular(
										:aria-label="t('account.title')"
										icon-left="person"
										:icon-right="active ? 'caret-up-outline' : 'caret-down-outline'")
								.account-info
									.user-details(v-if="user")
										span.singer-name {{ user?.name }}
										span(v-if="hasPremiumSub").user-email {{ user?.email }}
										SingaButton.is-pill.is-rounded.is-primary.nav-dropdown-button(tag="a" v-else :href="story.freemium_songs_link.cached_url") {{ t('button.freeSongs', { count: numberOfFreeSongsLeft }) }}
								hr(v-if="user")
								.links
									template(v-for="link in story.links")
										SingaDropdownItem(v-if="link.component === 'StoryblokLink'" tag="div")
											StoryblokLink(:blok="link")
										.dropdown-item(v-else-if="link.component === 'StoryblokSongRequestModal'")
											StoryblokSongRequestModal.dropdown-item-clickable(:blok="link" tab-index="0")
									SingaDropdownItem(tag="div")
										SingaButton.is-full-ghost.is-text-cta.is-justify-content-flex-start.dropdown-item-clickable(@click="promptFeedBackForm()") {{ t('feedback.report.title') }}
									SingaDropdownItem(tag="div")
										SingaButton.is-full-ghost.is-text-cta.is-justify-content-flex-start.dropdown-item-clickable(@click="logout()") {{ t('general.logout') }}
						.nav-right(v-else-if="!loading")
							SingaButton.is-regular.is-full-ghost.log-in(tag="router-link" :to="localePath('/login/')") {{ story.cta_log_in }}
							SingaButton.is-regular.is-primary.recolor(:to="localePath(story.create_account_link.cached_url)" :target="story.create_account_link.target" tag="router-link" :style="{'background-color': story.button_bgColor}") {{ story.create_account_cta}}
						template(#fallback)
							.nav-right
								SingaSkeleton.skeleton.log-in-skeleton(type="Rectangle" :dimensions="{ height: 50, width: 166 }")
								SingaSkeleton.skeleton(type="Rectangle" :dimensions="{ height: 50, width: 96 }")
		.container.is-fluid.index-tabs-wrap(v-if="indexTabs && !hasScrolledHalfView && !exitSticky")
			.container.section
				.index-tabs(:class="{'discover': route.name?.toString().includes('karaoke'), 'my-library': route.name?.toString().includes('my-library'), 'songs': route.name?.toString().includes('songs_') }")
					.tab
						NuxtLink.discover-tab(:to="localePath('/karaoke')") {{ t('tabs.discover') }}
					.tab#search
						NuxtLink.browse-songs-tab(:to="localePath('/songs/')") {{ t('tabs.browseSongs') }}
					.tab#my-library
						NuxtLink.my-library-tab(:to="localePath('/my-library/')") {{ t('tabs.myLibrary') }}

	//- Mobile
	.navigation-mobile
		.container.section
			.top
				.left
					NuxtLink.flex-child(:to="localePath('/')")
						SingaLogo.singa-logo.start(:imgSrc="story.logo?.filename")
				.right
					SingaButton.is-white(@click="appModal()") {{ t('global.downloadApp') }}
					SingaButton.is-transparent-dark(v-if="!auth.isLoggedIn" icon-left="person" @click="loginModal()")
					SingaDropdown.nav-menu(v-else aria-role="list" :mobile-modal="false" position="bottom-left")
						template(#trigger="{ active }")
							SingaButton.is-transparent-dark(
								size="small"
								icon-left="person"
								:icon-right="active ? 'caret-up-outline' : 'caret-down-outline'")
						.user-wrap
							.user-info
								span.user-name.ellipsis {{username}}
								//- span.user-limits(v-if="user") {{ t('button.freeSongs', { count: numberOfFreeSongsLeft }) }}
						.links
							template(v-for="link in story.links")
								SingaDropdownItem(v-if="link.component === 'StoryblokLink'" tag="div")
									StoryblokLink(:blok="link")
								.dropdown-item(v-else-if="link.component === 'StoryblokSongRequestModal'")
									StoryblokSongRequestModal.dropdown-item-clickable.song-request(:blok="link" tab-index="0")

							SingaDropdownItem(aria-role="listitem" @click="promptFeedBackForm()") {{ t('feedback.report.title') }}
							SingaDropdownItem(aria-role="listitem" @click="logout()") {{ t('general.logout') }}
			.bottom(v-if="showSearchInputMobile")
				SearchInput.search-wrap.expanded(:placeholderText="story.cta_search_input")
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useUserStore } from '../../pinia/userStore'

import AuthCard from '~/components/auth/AuthCard.vue'

const DownloadApp = resolveComponent('ModalsDownloadApp')

const { $oruga } = useNuxtApp()
const route = useRoute()
const localePath = useLocalePath()
const auth = useAuth()
const userStore = useUserStore()
const { user, username, hasPremiumSub, numberOfFreeSongsLeft, loading } = storeToRefs(userStore)
const { t } = useI18n()
const { promptFeedBackForm } = useSentry(t)

const isVenuePage = computed(() => {
	return route.fullPath.includes('karaoke-near-me')
})
const isVenueSubPage = computed(() => {
	return route.fullPath.includes('karaoke-near-me') && route.fullPath.split('karaoke-near-me/')[1]?.length > 0
})

defineProps({
	story: {
		type: Object,
		default: null
	}
})

const isDiscoverKaraokePage = computed(() => {
	return route.name?.toString().includes('karaoke')
		&& !route.fullPath.includes('karaoke-near-me')
		&& !route.name?.toString().includes('genres-name-karaoke-id')
})

const showSearchInput = ref(route.name?.toString().includes('search') || isDiscoverKaraokePage.value || route.name?.toString().includes('songs_'))
const showSearchInputMobile = ref(route.name?.toString().includes('search') || isDiscoverKaraokePage.value || route.name?.toString().includes('songs_'))
const indexTabs = ref(isDiscoverKaraokePage.value || route.name?.toString().includes('my-library') || route.name?.toString().includes('songs_'))

const isSearchView = ref()
const hasScrolledHalfView = ref(false)
const exitSticky = ref(false)
const scrollsUp = ref(false)
const positionY = ref<number>(typeof window !== 'undefined' ? window.scrollY : 0)

const handleScroll = () => {
	if (import.meta.server) { return }

	positionY.value = window.scrollY
	const exitHeight = ((window.innerHeight / 2) + 180)
	if (window.scrollY >= (window.innerHeight / 2)) {
		hasScrolledHalfView.value = true
	} else {
		hasScrolledHalfView.value = false
	}
	// For scroll up
	if (scrollsUp.value && window.scrollY > window.innerHeight / 3 && window.scrollY < exitHeight) {
		exitSticky.value = true
	} else {
		exitSticky.value = false
	}
}

const scrollToTop = () => {
	if (import.meta.server) { return }

	window.scrollTo({
		top: 0,
		behavior: 'smooth'
	})
}

const goHome = () => {
	if (import.meta.server) { return }

	if (route.fullPath === localePath('/')) {
		scrollToTop()
	} else {
		window.scrollTo({
			top: 0,
			behavior: 'auto'
		})
	}
}

onMounted(() => {
	window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll)
})

const loginModal = () => {
	$oruga.modal.open({
		component: AuthCard,
		width: 1088
	})
}

const appModal = () => {
	$oruga.modal.open({
		component: DownloadApp,
		scroll: 'keep'
	})
}

watch(() => route.name, (value) => {
	showSearchInput.value = (isDiscoverKaraokePage.value || value?.toString().includes('my-library') || value?.toString().includes('songs_'))
	showSearchInputMobile.value = (value?.toString().includes('search') || isDiscoverKaraokePage.value || value?.toString().includes('songs_'))
	indexTabs.value = (isDiscoverKaraokePage.value || value?.toString().includes('my-library') || value?.toString().includes('songs_'))
})

watch(positionY, (value, previous) => {
	if (value < previous) {
		scrollsUp.value = true
	} else {
		scrollsUp.value = false
	}
})

const logout = async () => {
	await auth.logout(true, t('auth.loggedOut'))
}
</script>

<style lang="sass" scoped>
.navigation-wrap
	z-index: 50

.sticky
	position: sticky
	top: 0
	background-color: $transparent-black-84
	backdrop-filter: blur(10px)
	filter: drop-shadow(5px 5px 4px $transparent-black-56)
	animation: slide-in 0.3s ease-out forwards

	@keyframes slide-in
		0%
			transform: translateY(-100%)
			opacity: 0
		100%
			transform: translateY(0)
			opacity: 1

.nav-inner
	display: flex
	align-items: center
	justify-content: center
	height: auto
	width: 100%
	min-height: 33px

.navigation-desktop
	display: none
	position: relative
	transition: background-color 300ms
	transition: height 150ms
	transition: max-width 500ms
	flex-direction: column
	align-items: center
	border-bottom: 1px solid transparent
	min-height: remify(104px)
	.nav-inner
		padding-top: $spacing-24
		padding-bottom: $spacing-24
	.singa-logo
		width: 110px
	&:not(.bg-transparent)
		background-color: $transparent-white-4
	.nav-menu
		.dropdown-trigger
			.button
				padding: 13px $spacing-16
				:deep(.button-wrapper)
					gap: $spacing-8
	@media (min-width: $tablet)
		display: flex

.exit-sticky
	position: sticky
	top: 0
	background-color: $transparent-black-84
	backdrop-filter: blur(10px)
	filter: drop-shadow(5px 5px 4px $transparent-black-56)
	animation: slide-out 0.3s ease-in forwards

	@keyframes slide-out
		0%
			transform: translate(0)
			opacity: 1
		100%
			transform: translateY(-100%)
			opacity: 0

	&:not(.bg-transparent)
		background-color: $transparent-white-4
	&:not(.has-navigation-tabs)
		border-bottom: 1px solid rgba(255, 255, 255, 0.16)

.flex-child
	flex: 1
	display: flex
	justify-content: center
	&:first-child > svg
		margin-right: auto
	&:last-child > div
		margin-left: auto

.premium-cta
	margin-right: $spacing-24
	padding: 12px 20.5px !important
.log-in
	margin-right: $spacing-16
	display: flex
.user-button
	line-height: 1
	padding: 7px 10px 7px 10px

.navigation-mobile
	.singa-logo
		height: 40px
		width: auto
	.top
		display: flex
		align-items: center
		justify-content: space-between
		padding-bottom: $spacing-8
		.left
			position: absolute
		.right
			width: 100%
			.is-white.button
				margin-right: 12px
				height: 36px
				@include fontSize(xxs)
		@media (min-width: $mobile-small)
			align-items: center
			.left
				position: unset
			.right
				width: unset
	.right
		display: flex
		align-items: center
		justify-content: flex-end
		>:not(:last-child)
			margin: $spacing-16 0
	.bottom
		margin-bottom: $spacing-16
	.nav-menu
		:deep(a), .dropdown-item, .song-request
			&:hover
				color: $color-grey-30 !important
		.dropdown-item
			&:hover
				background-color: $transparent-white-8
		.dropdown-trigger
			.button
				padding: $spacing-8
				height: 36px
	@media (min-width: $mobile-small)
		padding-top: $spacing-32
		.right
			display: inline-block
			>:not(:last-child)
				margin: 0 12px 0 0
	@media (min-width: $tablet)
		display: none

.nav-menu
	.dropdown-trigger
		:deep(.icon)
			margin: 0
			height: 20px
			width: 20px

.search-wrap
	max-width: 428px
	margin: auto
	transition: max-width 500ms

.expanded
	max-width: 100%

.flex-child
	justify-content: flex-start

.user-wrap
	padding: 24px
	display: flex
	position: relative
	&:after
		content: ''
		height: 1px
		background-color: $transparent-white-8
		position: absolute
		bottom: 0
		width: calc(100% - 48px)
		left: 0
		right: 0
		margin: auto
	img
		width: 56px
		height: 56px
		border-radius: $radius-default
		margin-right: $spacing-16
	.user-info
		display: flex
		flex-direction: column
		.user-name
			@include font(basier, medium)
		.user-limits
			@include font(basier, medium)
			@include fontSize(xs)
			border-radius: $radius-round
			background-color: $primary
			color: black
			padding: 4px 12px
			margin-top: 4px
.index-tabs
	display: flex
	justify-content: center
	align-items: center
	position: relative
	width: 100%
	.tab
		display: flex
		justify-content: center
		align-items: center
		text-align: center
		width: 100%
		height: 100%
		max-width: 800px
		padding-bottom: $spacing-8
		@include fontSize(m)
		@include font(basier, medium)
		&:not(.router-link-active)
			&:after
				content: ""
				position: absolute
				bottom: 0
				height: 3px
				width: calc(100% / 3)
				background-color: $color-green-100
				opacity: 0
				transition: opacity 150ms
		&:hover
			a:not(.router-link-active)
				color: $color-grey-50
			&:after
				opacity: 1
		:hover:not(.router-link-active)
			&:after
				left: 0
		:hover:not(.router-link-active):not(.discover-tab)
			&:after
				left: calc(100% / 3)
		.discover-tab, .my-library-tab, .browse-songs-tab
			width: 100%
			height: 100%
		a
			color: $color-grey-60
			transition: color 150ms
			&.router-link-active
				color: $primary
				@include font(basier, semiBold)

	&:after
		content: ""
		position: absolute
		bottom: 0
		height: 3px
		width: calc(100% / 3)
		background-color: $primary
		transform: translateX(calc(100% / 3))
		transition: transform 200ms
		color: $primary

	&.discover
		&:after
			transform: translateX(-100%)
	&.my-library
		&:after
			transform: translateX(100%)
	&.songs
		&:after
			transform: translateX(0)

.index-tabs-wrap
	width: 100%
	position: absolute
	height: remify(37px)
	bottom: remify(-38px)
	border-bottom: remify(1px) solid $transparent-white-16
	background: $transparent-white-4
	padding: 0 !important

.navigation-wrap.sticky
	border-bottom: remify(1px) solid $transparent-white-16

.fade-absolute-left-enter-active,
.fade-absolute-left-leave-active,
.fade-absolute-left-enter-from,
.fade-absolute-left-leave-to
	position: absolute
	left: 1.5rem
	transition: position 500ms, opacity 250ms
	@media (min-width: $desktop)
		left: 3rem
.fade-absolute-right-enter-active,
.fade-absolute-right-leave-active,
.fade-absolute-right-enter-from,
.fade-absolute-right-leave-to
	position: absolute
	right: 1.5rem
	transition: position 500ms, opacity 250ms
	@media (min-width: $desktop)
		right: 3rem
.fade-absolute-left-enter-from,
.fade-absolute-right-enter-from,
.fade-absolute-left-leave-to,
.fade-absolute-right-leave-to
	opacity: 0

hr
	border: 1px solid $transparent-white-16
	width: calc(100% - 48px)
	margin: 0 auto $spacing-8

.account-info
	background-color: $color-grey-80
	display: flex
	padding: $spacing-8 $spacing-24 $spacing-24
	border-radius: $radius-default $radius-default 0 0

.user-details
	display: flex
	flex-direction: column
	overflow: hidden

.small-img
	width: 56px
	height: 56px
	border-radius: $radius-default

.singer-name
	@include fontSize(m)
	@include font(basier, medium)
	display: inline-block
	text-overflow: ellipsis
	white-space: nowrap
	overflow: hidden

.user-email
	display: inline-block
	text-overflow: ellipsis
	overflow: hidden
	@include fontSize(xs)
	@include font(basier, regular)

.nav-right
	display: flex
	align-items: center

.upsell-btn
	margin: 0 $spacing-16 0 $spacing-16

.placeholder-name
	padding-right: $spacing-16

.skeleton
	display: flex
.log-in-skeleton
	margin-right: $spacing-16
</style>
